import { render, staticRenderFns } from "./ChangePasswordRequest.vue?vue&type=template&id=358bce4d&scoped=true&"
import script from "./ChangePasswordRequest.vue?vue&type=script&lang=js&"
export * from "./ChangePasswordRequest.vue?vue&type=script&lang=js&"
import style0 from "./ChangePasswordRequest.vue?vue&type=style&index=0&id=358bce4d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "358bce4d",
  null
  
)

export default component.exports